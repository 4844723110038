<template>
  <div>
    <v-layout column wrap>
      <v-card>
        <v-toolbar dense dark color="primary">
          <v-toolbar-title v-if="position!='MERCHANT PARTNER'"><h4 class="font-weight-light">LIST OF
            PAID MEMBERS</h4>
          </v-toolbar-title>
          <v-toolbar-title v-if="position==='MERCHANT PARTNER'"><h4 class="font-weight-light">LIST OF
            MEMBERS</h4>
          </v-toolbar-title>
        </v-toolbar>
        <v-layout row wrap class="align-center mx-2 mt-2" v-if="position!='MERCHANT PARTNER'">
          <v-flex xs12 md4>
            <v-select
              v-model="district"
              dense
              outlined
              label="District/Club"
              :items="district_items"
              item-value="id"
              item-text="district_name"
              @change="get_list_of_new_member_items"
            ></v-select>
          </v-flex>
        </v-layout>
        <v-data-table
          :headers="position === 'MERCHANT PARTNER'?headers2:headers"
          :items="member_items"
          :search="search"
          :loading="data_table_loading"
          loading-text="Loading... Please wait"
        >
          <template v-slot:top>
            <v-toolbar flat v-if="position!='MERCHANT PARTNER'">
              <span class="headline">Total: {{totall}}</span>
              <v-divider
                class="mx-4"
                inset
                vertical
              ></v-divider>
              <span class="headline" v-if="can_print">
                                                Print
                                                <v-icon
                                                  class="mr-2"
                                                  color="warning"
                                                  @click="print_data()"
                                                >
                                                {{icons.mdiPrinter}}
                                                </v-icon>
                                            </span>
            </v-toolbar>
            <v-text-field
              class="mx-2"
              :append-icon="icons.mdiAccountSearchOutline"
              label="Search"
              single-line
              hide-details
              v-model="search"
            ></v-text-field>
          </template>
          <template v-slot:item="{ item }">
            <tr
            >
              <td class="text-center" v-if="position==='MERCHANT PARTNER'">
                <div>
                  <v-icon
                    class="mr-2"
                    color="info"
                    @click="verify_member(item)"
                  >
                    {{icons.mdiCheckDecagram}}
                  </v-icon>
                </div>
              </td>
              <td>{{ item.date_accomplished }}</td>
              <td>
                {{ item.cmf_no }}
              </td>
              <td>
                {{ item.last_name }}
              </td>
              <td>
                {{ item.first_name}}
              </td>
              <td>
                {{ item.middle_name }}
              </td>
              <td>
                {{ item.contact_no }}
              </td>
              <td>
                {{ item.latest_district!=null?item.latest_district.district.district_name:'' }}
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card>
    </v-layout>

    <snack-bar-dialog
      :snackbar_flag="this.snackbar"
      :color="this.snackbar_color"
      :snackbar_text="this.snackbar_text"
    />
  </div>
</template>

<script>
  import {mapActions, mapGetters} from 'vuex'
  import moment from "moment";
  import {mdiAccountSearchOutline, mdiPrinter, mdiCheckDecagram } from '@mdi/js'
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'

  export default {
    components: {
      snackBarDialog,
    },
    setup() {
      return {
        icons: {
          mdiAccountSearchOutline,
          mdiPrinter,
          mdiCheckDecagram ,
        },
      }
    },
    data() {
      return {
        key: 0,
        can_print: false,
        data_table_loading: true,
        district_items: [],
        district: '',
        search: '',

        officers: [],
        member_items: [],
        unpaid_members: 0,
        totall: 0,

        district_namee: '',

        headers: [
          {text: 'Effective Date', value: 'date_accomplished'},
          {text: 'CMF #', value: 'cmf_no'},
          {text: 'Last Name', value: 'last_name'},
          {text: 'First Name', value: 'first_name'},
          {text: 'Middle Name', value: 'middle_name'},
          {text: 'Contact #', value: 'contact_no'},
          {text: 'District', value: 'latest_district.district.district_name'},
        ],
        headers2: [
          {text: 'Verify', value: 'action'},
          {text: 'Effective Date', value: 'date_accomplished'},
          {text: 'CMF #', value: 'cmf_no'},
          {text: 'Last Name', value: 'last_name'},
          {text: 'First Name', value: 'first_name'},
          {text: 'Middle Name', value: 'middle_name'},
          {text: 'Contact #', value: 'contact_no'},
          {text: 'District', value: 'latest_district.district.district_name'},
        ],
      }
    },
    async mounted() {
      if (this.district_id === null) {
        if (this.position === 'MERCHANT PARTNER') {
          await this.district_items.push({
            'id': 0,
            'district_name': 'All',
          })
          this.district = 0
          await this.get_list_of_new_member_items()
        } else {
          this.list_of_district()
            .then(response => {
              this.district_items = response.data
              this.district_items.splice(0, 0, {
                id: 0,
                district_name: 'All'
              })
            })
        }
      } else {
        this.district_items.push({
          'id': this.district_id,
          'district_name': this.district_name,
        })
      }
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo']),
      ...mapGetters('authentication', ['branch_id', 'branch', 'branch_address', 'branch_contact_no', 'district_id', 'district_name', 'position','merchant_id']),
    },
    watch: {
      branch_id: function () {
        this.can_print = false
        this.month_of = ''
        this.list_of_new_member = []
      }
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('district', ['list_of_district']),
      ...mapActions('members_information', ['list_of_paid_members']),
      ...mapActions('list_of_partnerships', ['create_merchant_history']),
      get_list_of_new_member_items() {
        if (this.district_items.length > 0) {
          var index = this.district_items.map(function (x) {
            return x.id
          }).indexOf(this.district)
          if (index != -1) {
            this.data_table_loading = true
            const data = new FormData()
            data.append('district_id', this.district_items[index].id);
            this.district_namee = this.district_items[index].district_name;
            this.list_of_paid_members(data)
              .then((response) => {
                this.data_table_loading = false
                this.member_items = response.data
                this.can_print = this.member_items.length > 0
                this.totall = this.formatPrice(this.member_items.length * 1000)
              })
              .catch(error => {
                console.log(error)
              })
          }
        }
      },
      verify_member(item) {
        const data = new FormData()
        data.append('member_id', item.id);
        data.append('merchant_id', this.merchant_id);
        this.create_merchant_history(data)
          .then((response) => {
            this.change_snackbar({
              show: true,
              color: 'success',
              text: response.data,
            })
          })
          .catch(error => {
            console.log(error)
          })
      },
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
      print_data() {
        var imgData = this.company_logo
        var array = []
        array.push(
          [
            {text: 'Effective Date', alignment: 'center', style: 'label'},
            {text: 'CMF #', alignment: 'center', style: 'label'},
            {text: 'Name', alignment: 'center', style: 'label'},
            {text: 'Contact #', alignment: 'center', style: 'label'},
          ]
        )

        if (this.member_items.length > 0) {
          this.member_items.forEach(function (item) {
            array.push(
              [
                {text: item.date_accomplished, alignment: 'center'},
                {text: item.cmf_no, alignment: 'left'},
                {
                  text: item.last_name + ', ' + item.first_name + ' ' + item.middle_name,
                  alignment: 'left'
                },
                {
                  text: item.contact_no,
                  alignment: 'left'
                },
              ]
            )
          })
        } else {
          array.push(
            [
              '----',
              '----',
              '----',
              '----',
            ]
          )
        }
        //call pdfmake
        var pdfMake = require('pdfmake/build/pdfmake.js')
        if (pdfMake.vfs == undefined) {
          var pdfFonts = require('pdfmake/build/vfs_fonts.js')
          pdfMake.vfs = pdfFonts.pdfMake.vfs;
        }
        var docDefinition = {
          pageSize: 'LETTER',
          pageOrientation: 'portrait',
          content: [
            {
              columns: [
                {image: imgData, width: 54, height: 54, style: 'logo'},
                {
                  stack: [
                    'GOODLIFE COMMUNITY CARE FOUNDATION',
                    {text: this.branch_address, style: 'subheader'},
                    {
                      text: 'S.E.C REG. No. NA CONTACT NO.: ' + this.branch_contact_no,
                      style: 'subheader'
                    },
                  ],
                  style: 'header'
                },
              ]
            },
            {text: 'LIST OF PAID MEMBERS', style: 'title'},
            '================================================================================',
            {text: 'Printed as of: ' + moment().format('MMMM D, YYYY'), style: 'printed_label'},
            {
              text: 'District: ' + this.district_namee,
              text: 'Total: ' + this.totall,
              style: 'main_info'
            },
            ' ',
            {
              style: 'tableExample',
              color: '#444',
              table: {
                widths: [120, 50, 230, 80],
                body: array,
              },
              layout: {
                fillColor: function (rowIndex) {
                  return (rowIndex === 0) ? '#CCCCCC' : null;
                },
              },
            },
            {
              stack: [
                {
                  columns: [
                    {text: 'Prepared By: ', style: 'aa_top_margin'},
                    {text: 'Received By:', style: 'cashier_top_margin'},
                    {text: ''},
                  ]
                },
                {
                  columns: [
                    {text: '', style: 'aa_for'},
                    {text: '', style: 'cashier_for'},
                    {text: ''},
                  ]
                },
                {
                  columns: [
                    {text: '_________________________________', style: 'aa_line'},
                    {text: '_________________________________', style: 'cashier_line'},
                    {text: ''},
                  ]
                },
              ]
            },
            {
              stack: [
                {
                  columns: [
                    {text: 'Verified By: ', style: 'bm_top_margin'},
                    {text: 'Audited By: ', style: 'au_top_margin'},
                  ]
                },
                {
                  columns: [
                    {text: '', style: 'bm_for'},
                    {text: '', style: 'au_for'},
                  ]
                },
                {
                  columns: [
                    {text: '_________________________________', style: 'bm_line'},
                    {text: '_________________________________', style: 'au_line'},
                  ]
                },
                {
                  columns: [
                    {text: 'Branch Manager', style: 'bm_placeholder'},
                    {text: 'Asst. Auditor', style: 'au_placeholder'},
                  ]
                },
              ]
            },
          ],
          footer: {
            columns: [
              {
                text: 'Generated with Goodlife Savings & Credit Cooperative System',
                alignment: 'center',
                style: 'tableExample'
              }
            ]
          },
          styles: {
            header: {
              fontSize: 21,
              bold: true,
              alignment: 'left',
              margin: [6, 6, 0, 20]//[left, top, right, bottom]
            },
            subheader: {
              fontSize: 12
            },
            title: {
              fontSize: 15,
              alignment: 'center',
            },
            logo: {
              alignment: 'center',
              margin: [0, 0, 0, 0]//[left, top, right, bottom]
            },
            printed_label: {
              alignment: 'right',
              fontSize: 9,
              margin: [0, 4, 0, 0]//[left, top, right, bottom]
            },
            main_info: {
              margin: [0, 2, 0, 2],
              fontSize: 10,
            },
            sub_info: {
              margin: [0, 2, 0, 2],
              fontSize: 8,
            },
            tableExample: {
              fontSize: 7,
            },

            aa_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            cashier_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            bm_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            cs_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            aa_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cashier_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cs_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            bm_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            aa_placeholder: {
              margin: [15, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            aa_for: {
              margin: [0, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cs_for: {
              margin: [2, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cashier_for: {
              margin: [3, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            bm_for: {
              margin: [3, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cashier_placeholder: {
              margin: [26, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cs_placeholder: {
              margin: [2, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            bm_placeholder: {
              margin: [5, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            au_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            au_placeholder: {
              margin: [2, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            au_for: {
              margin: [2, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            au_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
          },
        }
        pdfMake.createPdf(docDefinition).open();
      },
    }
  }
</script>
